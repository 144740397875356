.App {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &-heading {
    line-height: 0rem;
  }

  &-subheading {
    span:nth-child(1n) {
      color: $color-text-highlight-primary;
    }
    span:nth-child(2n) {
      color: $color-text-highlight-secondary;
    }
    span:nth-child(3n) {
      color: $color-text-lowlight-primary;
    }
  
  }
}

